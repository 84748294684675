.achievements-list li {
  text-align: left;
}

.achievements-list li i, .facts-list li i, .work-achievements li i {
  color: #009688;
}

.education-section .university-logo {
  height: 90px;
  padding-bottom: 10px;
}

.education-section .item {
  margin-bottom: 20px;
}

.section-title {
  font-size: 30px;
}

.section-title small {
  font-size: 15px;
}

.skills-section .subtitle {
  font-size: 24px;
  margin-bottom: 35px;
  font-weight: 400;
}

.skills-section .skill-icon {
  margin-bottom: 10px;
}

.skills-section .skill-icon i {
  font-size: 64px;
}

.fa-python {
  color: green;
}

.fa-react {
  color: rgb(97, 218, 251);
}

.skills-section .sub-subtitle {
	font-size: 18px;
  color: #8a8a8a;
  margin-top: 40px;
  margin-bottom: 15px;
  font-weight: 300;
}

.skills-section .other-skills {
	margin-bottom: 60px;
}

.skills-section .top-skills .item {
    margin-bottom: 0;
}

.skills-section .personal-skills .skill-tag {
	width: 210px;
}

.portfolio-section .item .content {
  height: 250px;
}

.about-section .intro {
    position: relative;
    padding: 30px;
    font-size: 16px;
    color: #666;
    text-align: center;
    width: 100%
}

.about-section .profile-image {
  height: 180px;
  margin-bottom: 30px;
}

.about-section .dialog .subtitle {
  text-align: center;
  font-size: 24px;
  font-weight: 400;
}

.about-section .dialog {
  text-align: justify;
}

.talk-picture {
  width: 25%;
  display: inline-block;
  vertical-align: bottom;
}

.talk-picture img {
  width: 100%;
  height: 150px;
  object-fit: scale-down;
}

.talk-info {
  width: 75%;
  display: inline-block;
}

.talk-info .dialog {
  color: #666;
}

.item-img-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-img-container img {
  display: block;
  max-width: 50%;
  height: auto;
  max-height: 250px;
  margin: 0 auto;
  border-radius: 5px;
}

.item-img-container .image-title {
  font-family: 'Staatliches', cursive;
  position: absolute;
  margin: 0;
  text-align: center;
  max-width: 40%;
  color: white;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  background: none !important;
}

.item-img-container .carousel img {
  max-width: 70%;
  max-height: 300px;
}

@media (max-width: 767px) {
  .page-nav-wrapper .page-nav li {
    margin: 0;
    padding: 3px;
  }

  #filters {
    padding-left: 0;
  }

  .portfolio-section .item-inner .content .meta {
    max-width: 200px;
    text-overflow: unset;
    white-space: pre-line;
  }

  .timeline .location {
    position: relative;
  }

  .talk-picture {
    width: 100%;
    display: block;
    padding: 0 30px;
    margin-bottom: 15px;
  }

  .talk-info {
    width: 100%;
    display: block;
  }

  .item-img-container img {
    max-width: 100%;
  }

  .item-img-container .image-title {
    max-width: 50%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .timeline .location {
    position: relative;
    margin-bottom: 5px;
  }
}